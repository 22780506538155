import { useEffect, useState } from "react"
import { useRevalidator } from "react-router-dom"
import InputTextField from "../../Components/InputTextField"
import InputTextArea from "../../Components/InputTextArea"
import { createCheckpointApiCall, updateCheckpointApiCall } from "../../api/MemoryCard"
import InputNumberField from "../../Components/InputNumberField"
import InputCheckbox from "../../Components/InputCheckbox"


export default function CreateUpdateCheckpointModal(props) {
    const [title, setTitle] = useState()
    const [text, setText] = useState()
    const [playtime, setplaytime] = useState()
    const [final, setFinal] = useState()

    var createOrUpdate = props.checkpointData ? 'update':'create'
    var submitText =  props.checkpointData ? 'Update': 'Create'

    const revalidator = useRevalidator();


    async function createUpdateCheckpoint(event) {
        event.preventDefault();
        const form = event.target
        const formData = new FormData(form);
        formData.append("game", props.game_id)
        if (createOrUpdate === 'create'){
            await createCheckpointApiCall(formData)
        }
        else {
            await updateCheckpointApiCall(props.checkpointData.id, formData)
        }
        revalidator.revalidate()
        await closeModal(true)
    }


    async function closeModal() {
        props.setVisible(false)
    }

    useEffect(() => {
        if (createOrUpdate==='update') {
            console.log(props.checkpointData)

            setTitle(props.checkpointData.title)
            setText(props.checkpointData.text)
            setplaytime(props.checkpointData.playtime)
            setFinal(props.checkpointData.final)
        }
        else {
            // maybe move this to close modal?
            setTitle("")
            setText("")
            setplaytime("")
            setFinal("")
        }

    }, [props.visible])
    
    if (props.visible) 
    {
        return (
            <div className='modal-wrapper' onClick={()=> closeModal()}>
                <div className='modal checkpoint-modal four-corner-cut' onClick={e => e.stopPropagation()}>
                    <form  onSubmit={createUpdateCheckpoint}>
                        <InputTextField labelText="Title" name="title" state={title} setState={setTitle} required={true}/>
                        <InputNumberField labelText="Hours Played" name="playtime" state={playtime} setState={setplaytime}/>
                        <InputCheckbox labelText="Final Checkpoint?" name="final" state={final} setState={setFinal}/>
                        <InputTextArea labelText="Text" name="text" state={text} setState={setText} required={true}/>
                        <div className='control-buttons'>
                            <button className="corner-cut" type="submit">{submitText}</button>
                            <button className="close negative-button corner-cut" onClick={() => closeModal()}>Close</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
        
}