export default function ConfirmDialog(props) {
    const confirmText = props.confirmText? props.confirmText: "Confirm?"
    const cancelText = props.confirmText? props.confirmText: "Cancel"

    if (props.visible) {
        return (
            <div className="confirm-dialog-parent">
                <div className="confirm-dialog">
                    <button className="negative-button corner-cut" onClick={() => props.functionOnConfirm()}>{confirmText}</button>
                    {/* <button onClick={() => props.setVisible(false)}>{cancelText}</button> */}
                </div>
            </div>
        )
    }

} 