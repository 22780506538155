import InputTextField from '../../Components/InputTextField.jsx';
import InputNumberField from '../../Components/InputNumberField.jsx';
import InputSelectField from '../../Components/InputSelectField.jsx';
import InputDateField from '../../Components/InputDateField.jsx';

import { createGameApiCall } from '../../api/MemoryCard.js' 
import {GAME_STATUS} from '../../data/gameStatus.js'
import InputCheckbox from '../../Components/InputCheckbox.jsx';
import { useState } from 'react';

export default function NewGame(props) {

    const [errorMessage, setErrorMessage ] = useState()

    const setPanelVisible = props.setPanelVisible

    async function createGame(event) {
        event.preventDefault();
        const form = event.target
        const formData = new FormData(form);
        
        const response = await createGameApiCall(formData)
        if (response.ok) {
            setPanelVisible(false)
            setErrorMessage()
            props.refreshGameList()
        }
        else {
            setErrorMessage('There was an error creating the game')
        }

    }

    const game_status_options = GAME_STATUS

    return (
        <div  className="new-game">
            <form  onSubmit={createGame}>
                {errorMessage && <div className='error-block'>
                    {errorMessage}
                </div>}

                <InputTextField labelText="Game Name" name="name" required={true}/>

                <InputSelectField labelText="Game Status" name="status" options={game_status_options} required={true}/>
                <InputNumberField labelText="Rating" name="rating" />

                <InputDateField labelText="Date Purchased" name="date_purchased"/>
                <InputNumberField labelText="Purchased For" name="price_when_purchased" step=".01"/>

                <InputDateField labelText="Date Started" name="date_started"/>
                <InputDateField labelText="Date Completed" name="date_completed"/>
                
                <InputNumberField labelText="Hours When Completed" name="hours_when_completed"/>
                <InputNumberField labelText="Total Hours Played" name="total_hours_played"/>

                <InputCheckbox labelText="Continuous" name="continuous"/>
                <InputCheckbox labelText="Currently Playing" name="currently_playing"/>

                <div className='control-buttons'>
                    <button type="submit">Add</button>
                    <button className="close negative-button" onClick={() => setPanelVisible(false)}>Close</button>
                </div>
            </form>
        </div>
    )
}