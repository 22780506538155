export default function InputDateField(props) {
    async function controlledUpdate(element) {
        if (!!props.setState) {
            props.setState(element.target.value)
        }
    }

    return (
        <div className="input-wrapper date-input">
            <label htmlFor={props.name}>
                {props.labelText}{!!props.required &&'*'}: 
            </label>
            <input name={props.name} id={props.name} type="date" required={!!props.required}
                value={props.state} onChange={(el) => controlledUpdate(el)}
            />
        </div>
    )
}