import { Link, useRouteError } from "react-router-dom";


export default function DefaultFallBack() {
    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined
    return <div className="default-fall-back">
        <div className="content">
            Crash! You've hit an error
            <br/>
            Refresh the page 
            <br/>
            or 
            <br/>
            <Link to='/'> Click Here To Go Home</Link>
        </div>
    </div>
}