export default function InputCheckbox(props) {

    async function controlledUpdate(element) {
        if (!!props.setState) {
            props.setState(element.target.checked)
        }
    }

    return (
        <div className="input-wrapper checkbox-input">
            <label htmlFor={props.name}>
                {props.labelText}: 
            </label>
            {(props.state === undefined) ?
                <input 
                name={props.name} id={props.name} type="checkbox" hidden
                />
                :
                <input 
                name={props.name} id={props.name} type="checkbox" hidden
                checked={props.state} onChange={(el) => controlledUpdate(el)} 
                />
            }
            
            <label className="toggler-slider" htmlFor={props.name}>
                    <div className="toggler-knob"/>
            </label>
        </div>
    )
}