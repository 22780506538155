

export default function InputTextArea(props){
    async function controlledUpdate(element) {
        if (!!props.setState) {
            props.setState(element.target.value)
        }
    }

    return (
        <div className="input-wrapper text-area">
            <label htmlFor={props.name}>
                {props.labelText}{!!props.required &&'*'}: 
            </label>
            <textarea name={props.name} id={props.name} required={!!props.required}
                value={props.state} onChange={(el) => controlledUpdate(el)}
            />
        </div>
    )
}

/*

autofocus	autofocus	Specifies that a text area should automatically get focus when the page loads
cols	number	Specifies the visible width of a text area
dirname	textareaname.dir	Specifies that the text direction of the textarea will be submitted
disabled	disabled	Specifies that a text area should be disabled
form	form_id	Specifies which form the text area belongs to
maxlength	number	Specifies the maximum number of characters allowed in the text area
name	text	Specifies a name for a text area
placeholder	text	Specifies a short hint that describes the expected value of a text area
readonly	readonly	Specifies that a text area should be read-only
required	required	Specifies that a text area is required/must be filled out
rows	number	Specifies the visible number of lines in a text area
wrap	hard/soft	Specifies how the text in a text area is to be wrapped when submitted in a form
*/