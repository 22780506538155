import { useEffect, useState } from "react"
import { deleteFromQueueApiCall, queueSetPlaying, queueSwapPositionsApiCall } from "../../api/MemoryCard"
import { Link } from "react-router-dom"
import { formatDate } from "../../helpers/date_helper"

export default function GameQueue(props){
    const queue = props.queue

    const [randomSelected, setRandomSelected] = useState()

    async function swapQueuePosition(list_index, position_to_swap) {
        const id1 = queue[list_index].id
        const id2 = queue[position_to_swap].id
        const data = {ids: [id1, id2]}
        const newQueue = await queueSwapPositionsApiCall(data);
        props.setGameQueue(newQueue)
    }

    async function selectRandomGame() {
        var random_no = Math.floor(Math.random() * queue.length)
        if (random_no == randomSelected) {
            var random_no = (random_no+1) % queue.length
        }
        setRandomSelected(random_no)
    }

    async function startPlayingRandom() {
        var queue_item_date_started = queue[randomSelected].game_date_started
        const data = {
            date_started: queue_item_date_started? queue_item_date_started: formatDate(new Date(), 'programmer')
        }

        const newQueue = await queueSetPlaying(queue[randomSelected].id, data)
        props.setGameQueue(newQueue)
        props.refreshCurrentlyPlaying()
        setRandomSelected(undefined)
    }

    return (
        <div className="game-queue">
            <div className="header-block">
                <div className="action-block" onClick={() => props.setDisplay('CP')}>
                    <h2 className="currently-playing-toggle">View Playing</h2>
                </div>
                <div className="title">
                    <h1>Game Queue</h1>
                </div>
                <div className="action-block" onClick={() => props.setDisplay('STATS')}>
                    <h2 className="stats-toggle">Stats</h2>
                </div>
            </div>
            {props.loggedIn && queue.length > 0 &&
                <div className="random-select">
                        {randomSelected==undefined ? 
                            <button className="corner-cut" onClick={() => selectRandomGame()}>Select Random</button>                        
                            :
                            <div className="random-selected-game">
                                <h2>{queue[randomSelected].game_name}</h2>
                                <div className="buttons">
                                    <button className="corner-cut secondary-button" onClick={() => selectRandomGame()}>Roll Again</button>
                                    <button className="corner-cut" onClick={() => startPlayingRandom()}>Start Playing</button>
                                </div>
                            </div>
                        }
                </div>
            }
            <div className="queue">
                {queue.map((queue_item, index) => 
                    <QueueItem
                        queue_item={queue_item} key={queue_item.id} index={index} queueLength={queue.length}
                        setGameQueue={props.setGameQueue} swapQueuePosition={swapQueuePosition} 
                        refreshCurrentlyPlaying={props.refreshCurrentlyPlaying} 
                        refreshGameQueue={props.refreshGameQueue}
                        loggedIn={props.loggedIn}
                    />
                )}
                {queue.length == 0 &&
                    <div className="empty-queue">
                       <Link to='/memory-card/'><h1>Queue is empty, I should Add something to it</h1></Link>
                    </div>
                }
            </div>
        </div>
    )
}


// maybe create own file for this?
function QueueItem(props) {

    const [dateStarted, setDateStarted] = useState('')
    const queue_item = props.queue_item
    const index = props.index
    
    async function setPlaying(queue_id) {
        const data = {
            date_started: dateStarted
        }
        const newQueue = await queueSetPlaying(queue_id, data)
        props.setGameQueue(newQueue)
        props.refreshCurrentlyPlaying()
    }

    async function removeFromQueue(queue_id) {
        await deleteFromQueueApiCall(queue_id)
        props.refreshGameQueue()
    }

    useEffect(() => {
        const gameDateStarted = props.queue_item.game_date_started
        setDateStarted(gameDateStarted != null ? gameDateStarted: '')
    }, [props.queue_item])

    return (
        <div className={`queue-item ${queue_item.game_status === 'W'?'wishlist':''}`} key={queue_item.game}>
            {index!= 0 && props.loggedIn &&
                <button className="shift-button up-button" onClick={() => props.swapQueuePosition(index, index-1)}>
                    <i className="up-arrow icon-size-16 white-filter"/>
                </button>}
            <h1> 
                {index+1}| <Link to={`/memory-card/${queue_item.game}`}>{queue_item.game_name}</Link>
                {props.loggedIn &&
                    <div className="queue-buttons">
                        <button className="remove negative-button" onClick={() => removeFromQueue(queue_item.id)}><i className="cross icon-size-24 white-filter"/></button>
                        <input type='date' value={dateStarted} onChange={(el) => setDateStarted(el.target.value)}/>
                        <button className="set-playing" onClick={() => setPlaying(queue_item.id)}><i className="fast-forward icon-size-24 white-filter"/></button>
                    </div>
                }
            </h1>
            {index!= (props.queueLength -1) && props.loggedIn &&
                <button className="shift-button down-button" onClick={() => props.swapQueuePosition(index, index+1)}>
                    <i className="down-arrow icon-size-16 white-filter"/>
                </button>
            }
        </div>
    )

}