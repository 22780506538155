import { useLoaderData } from "react-router-dom"
import "../../CSS/Stats.css"
import GameDisplayField from "../../Components/GameDisplayField"

export default function Stats(props) {

    const stats = useLoaderData().stats;
    console.log(stats)
    const thisYear = new Date().getFullYear()
    
    
    return (
        <div className="game-stats">
            <div className="header-block">
                <div className="action-block" onClick={() => props.setDisplay('GQ')}>
                    <h2 className="currently-playing-toggle">View Queue</h2>
                </div>
                <div className="title">
                    <h1>Stats</h1>
                </div>
            </div>

            <div class='stats-block four-corner-cut'>
                <GameDisplayField label="Total Games Completed:" display={stats.total_games_completed}/>
                <GameDisplayField label="" display={""}/>
                <GameDisplayField label={`Games Completed This Year (${thisYear}):`} display={stats.games_completed_this_year}/>
                <GameDisplayField label={`Games Completed Last Year (${thisYear-1}):`} display={stats.games_completed_last_year}/>
                <GameDisplayField label="Total hours recorded:" display={stats.total_hours_recorded}/>
                <GameDisplayField label="Game With Most Hours:" display={`${stats.game_with_most_hours.name} (${stats.game_with_most_hours.total_hours_played} hrs)`}/>
            </div>
        </div>
    )
}