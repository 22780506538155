import { useEffect, useState } from 'react';
import InputTextField from './InputTextField.jsx';
import { uploadImageApiCall } from '../api/MemoryCard.js';
import InputFileUpload from './InputFileUpload.jsx';


export default function GameDisplayCarousel(props) {
    const [currentImage,  setCurrentImage] = useState(0)
    const [showAddForm, setShowAddForm] = useState(false)
    const imageList = props.imageList


    const changeScreenshot = (change) => {
        let imageCount = imageList.length
        if (change != null){ 
            setCurrentImage((currentImage + imageCount + change) % imageCount)
        }
    }

    const carouselIndicators = ()  => {
        let indicatorList  = []
        if (imageList.length === 1) {
            return
        }

        for(let i=0; i < imageList.length; i++) {
            let className = 'carousel-indicator'
            if (currentImage === i) {
                className += ' active'
            }
            indicatorList.push(<button key={i} className={className} onClick={() => setCurrentImage(i)}></button>)
        }
        return indicatorList
    }

    async function uploadImage(event) {
        event.preventDefault();
        const form = event.target
        const formData = new FormData(form);
        formData.append('game', props.gameId)
        var response = await uploadImageApiCall(formData)

        // console.log(response)
        setShowAddForm(false)
        var numberOfImages = await props.refreshImageList()
        setCurrentImage(numberOfImages - 1)
    }

    useEffect(() => {
        if (currentImage >= imageList.length) {
            setCurrentImage(0)
        }
    }, [imageList])

    return (
        <div className='image-panel'>
            <img 
                src={imageList[currentImage]?.image} title={imageList[currentImage]?.title} 
                alt={imageList[currentImage]?.alt_text}
                onClick={() => props.updateFullSizeImageModal(imageList[currentImage])}
            />
            
            <button className="prev carousel-button" onClick={() => changeScreenshot(-1)}>
                <i className="angle-small-left icon-size-32 white-filter"/>
            </button>
            <span className='position carousel-button'>
                {carouselIndicators()}
            </span>
            <button className="next carousel-button" onClick={() => changeScreenshot(1)}>
                <i className="angle-small-right icon-size-32 white-filter"/>
            </button>
            {!!showAddForm &&
                <div className='upload-image-modal'>
                    <form onSubmit={uploadImage}>
                        <InputTextField name='title' labelText='Image Title' required={true}/>
                        <InputFileUpload name='image' labelText='Image File' required={true} accept='image/*' />
                        <InputTextField name='alt_text' labelText='Alt Text'/>
                        <button type='submit'>Upload Image</button>
                    </form>
                </div>
            }
            {props.loggedIn && 
                <button className={`add carousel-button ${showAddForm? 'active-green':''}`} onClick={() => setShowAddForm(~showAddForm)}>
                    <i className='plus white-filter'/>
                </button>
            }

        </div>
    )
}