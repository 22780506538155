export default function InputFileUpload(props) {
    async function controlledUpdate(element) {
        if (!!props.setState) {
            props.setState(element.target.value)
        }
    }

    return (
        <div className="input-wrapper file-upload">
            <label htmlFor={props.name}>
                {props.labelText}{!!props.required &&'*'}: 
            </label>
            <input name={props.name} id={props.name} type="file" required={!!props.required}
                accept={props.accept} value={props.state} onChange={(el) => controlledUpdate(el)}
            />
        </div>
    )
}