import { Link } from "react-router-dom";

export default function NotFound(props){
    return <div className="not-found">
        <div className="content four-corner-cut">
            404 Page Not Found
            <br/>
            <Link to='/'>Click Here To Go Home</Link>
        </div>
    </div>
}