const api_url = process.env.REACT_APP_API_URL;

// Games
export async function gamesListApiCall(list_type) {
  const response = await fetch(`${api_url}/memory-card/games/${list_type}/`);
  return await response.json();
}

export async function gameDetailsApiCall(game_id) {
  const response = await fetch(`${api_url}/memory-card/games/${game_id}/`);
  return await response.json();
}

export async function gameFullInfoApiCall(gameId) {
  const response = await fetch(`${api_url}/memory-card/games/${gameId}/full_info/`);
  return response;
}

export async function createGameApiCall(formData) {
  const token = localStorage.getItem('token');

  const headers = {'Authorization': `Token ${token}`};

  const response = await fetch(
      `${api_url}/memory-card/games/`,
      {method: 'post', body: formData, headers: headers},
  );
  return response;
}

export async function updateGameApiCall(game_id, data) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(
      `${api_url}/memory-card/games/${game_id}/`,
      {method: 'put', body: JSON.stringify(data), headers: headers},
  );
  return response;
}

export async function patchGameApiCall(game_id, data) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(
      `${api_url}/memory-card/games/${game_id}/`,
      {method: 'PATCH', body: JSON.stringify(data), headers: headers},
  );
  return response;
}

export async function deleteGameApiCall(gameId) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
  };
  const response = await fetch(
      `${api_url}/memory-card/games/${gameId}/`,
      {method: 'delete', headers: headers},
  );
  return response;
}

// Checkpoints
export async function getCheckpointApiCall(checkpointId) {
  const response = await fetch(`${api_url}/memory-card/checkpoints/${checkpointId}/`);
  return await response.json();
}

export async function createCheckpointApiCall(formData) {
  const token = localStorage.getItem('token');
  const headers = {'Authorization': `Token ${token}`};

  const response = await fetch(
      `${api_url}/memory-card/checkpoints/`,
      {method: 'post', body: formData, headers: headers},
  );
  return response;
}

export async function updateCheckpointApiCall(checkpointId, formData) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
  };

  const response = await fetch(
      `${api_url}/memory-card/checkpoints/${checkpointId}/`,
      {method: 'put', body: formData, headers: headers},
  );
  return response;
}

export async function deleteCheckpoinApiCall(checkpointId) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
  };

  const response = await fetch(
    `${api_url}/memory-card/checkpoints/${checkpointId}/`,
    {method: 'delete', headers: headers},
  );
  return response;
}

// Images
export async function gameImagesApiCall(game_id) {
  const response = await fetch(`${api_url}/memory-card/images/?game=${game_id}`);
  const images = await response.json();
  return images;
}

export async function uploadImageApiCall(formData) {
  const token = localStorage.getItem('token');
  const headers = {'Authorization': `Token ${token}`};

  const response = await fetch(
      `${api_url}/memory-card/images/`,
      {method: 'post', body: formData, headers: headers},
  );
  return response;
}

export async function deleteImageApiCall(imageId) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
  };
  const response = await fetch(
      `${api_url}/memory-card/images/${imageId}/`,
      {method: 'delete', headers: headers},
  );
  return response;
}

// queue
export async function queueListApiCall() {
  const response = await fetch(`${api_url}/memory-card/queue/`)
  return await response.json();
}

export async function queueSetPlaying(queue_id, data={}) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(
    `${api_url}/memory-card/queue/${queue_id}/set_playing/`,
    {method: 'POST', body: JSON.stringify(data),  headers: headers}
  )
  return await response.json();
}


export async function queueSwapPositionsApiCall(data) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(
    `${api_url}/memory-card/queue/swap_positions/`,
    {method: 'POST', body: JSON.stringify(data),  headers: headers}
  )
  return await response.json();
}

export async function queueAddAPiCall(game_id) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const data = {
    game: game_id
  }

  const response = await fetch(
    `${api_url}/memory-card/queue/add_to_queue/`,
    {method: 'POST', body: JSON.stringify(data),  headers: headers}
  )
  return await response.json();
}

export async function deleteFromQueueApiCall(queue_id) {
  const token = localStorage.getItem('token');
  const headers = {
    'Authorization': `Token ${token}`,
    'Content-Type': 'application/json',
  };

  const response = await fetch(
    `${api_url}/memory-card/queue/${queue_id}/`,
    {method: 'DELETE',  headers: headers}
  )

  return response;
}

// stats


export async function getGameStatsApiCall() {
  const response = await fetch(`${api_url}/memory-card/games/fun_stats/`);
  return await response.json();
}
