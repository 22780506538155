import '../../CSS/Checkpoint.css'
import { useState } from "react"
import { getCheckpointApiCall, deleteCheckpoinApiCall } from '../../api/MemoryCard.js';
import { useRevalidator } from "react-router-dom"
import ConfirmDialog from '../../Components/ConfirmDialog.jsx';


export default function Checkpoint(props) {

    const [showText, setShowText] = useState(false)
    const [checkpointData, setCheckpointData] = useState()
    const [showDeleteDialog, setShowDeleteDialog] = useState()

    const checkpoint = props.checkpoint
    const revalidator = useRevalidator();

    async function toggleCheckpointEntry() {
        if (!showText) {            
            const checkpointData = await getCheckpointApiCall(checkpoint.id)
            setCheckpointData(checkpointData)
        }
        setShowText(~showText)
    }

    async function deleteCheckpoint() {
        await deleteCheckpoinApiCall(checkpoint.id)
        revalidator.revalidate()
    }

    async function editCheckpoint() {
        const checkpointData = await getCheckpointApiCall(checkpoint.id)
        props.openModal(checkpointData)
    }

    return (
        <div className={`checkpoint-entry clickable ${checkpoint.final? 'final': ''}`} onClick={() => toggleCheckpointEntry()}>
                <h2 className='checkpoint-title' >
                    {checkpoint.title}
                    {props.loggedIn &&
                        <div className='checkpoint-buttons' onClick={e => e.stopPropagation()}>
                            <ConfirmDialog visible={showDeleteDialog} functionOnConfirm={deleteCheckpoint} setVisible={showDeleteDialog}/>
                            <button className='negative-button corner-cut' onClick={() => setShowDeleteDialog(~showDeleteDialog)}>Delete</button>
                            <button className='corner-cut' onClick={() => editCheckpoint()}>Edit</button>
                        </div>
                    }
                </h2>
            {!!showText && 
                <>
                    {checkpointData.playtime? <p>Hours Played: {checkpointData.playtime}</p>: ''}
                    <p className='checkpoint-text'>{checkpointData.text}</p>
                </>
            }
            <i className={`${!!showText? 'up-arrow' :'down-arrow' } icon-size-20 white-filter`}/>
        </div>
    )
}