

export default function InputSelectField(props) {
    async function controlledUpdate(element) {
        if (!!props.setState) {
            props.setState(element.target.value)
        }
    }

    const options = Object.entries(props.options).map(([value, text]) => 
    {   
        return <option value={value} key={value}>{text}</option>
    })

    return (
        <div className="input-wrapper select-input">
            <label htmlFor={props.name}>
                {props.labelText}{!!props.required &&'*'}: 
            </label>
            <select id={props.name} name={props.name} value={props.state} onChange={(el) => controlledUpdate(el)}
                    required={!!props.required}
            >
                {options}
            </select>
        </div>
    )
}