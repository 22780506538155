// import {format} from 'date-fns'

export function formatDate(inputDate, dateFormat) {
  if (inputDate === null) {
    return null;
  }

  var dateObj;
  if (typeof inputDate === Date){
    var dateObj = inputDate
  }  
  else {
    var dateObj = new Date(inputDate);
  }

  const locale = navigator.language

  switch (dateFormat) {
    case 'short': {
      return dateObj.toLocaleDateString(locale, 'short')
    }
    case 'shortDayFullMonth':{
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
      return dateObj.toLocaleDateString(locale, options);
    }
    case 'shortWithTime': {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour12: true,
      }
      return dateObj.toLocaleTimeString(locale, options);
    }
    case 'full':{
      const options = {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      };
      return dateObj.toLocaleDateString(locale, options);
    }
    case 'onlyMonthYear':
      return Intl.DateTimeFormat(locale, {month: 'long'}).format(dateObj) + ' ' + dateObj.getFullYear();
    case 'programmer':
      return dateObj.toISOString().slice(0,10)
    default:
      throw new Error('Undefined Date format for helper function');
  }
}