// import { useEffect } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import CurrentlyPlaying from './CurrentlyPlaying';
import { useState } from 'react';
import GameQueue from './GameQueue';
import { gamesListApiCall, queueListApiCall } from '../../api/MemoryCard';
import Stats from './Stats';


export default function Home(props) {
    const home = useLoaderData();

    const [display, setDisplay] = useState('CP')
    const [currentlyPlaying, setCurrentlyPlaying] = useState(home.currentlyPlaying);
    const [gameQueue, setGameQueue] = useState(home.gameQueue)
    const [dialogOpenIndex, setDialogOpenIndex] = useState()


    const loggedIn = props.loggedIn

    async function refreshCurrentlyPlaying(){
        // Todo figure out why I need to clear it first.
        // ? its becasue the fields state is set upon component creation,
        await setCurrentlyPlaying()
        setDialogOpenIndex()
        setCurrentlyPlaying(await gamesListApiCall("currently_playing"))
    }

    async function refreshGameQueue() {
        setGameQueue(await queueListApiCall())
    }

    async function refreshHomePageData(){
        refreshCurrentlyPlaying()
        refreshGameQueue()
    }

    if (currentlyPlaying) {
        for (let i=currentlyPlaying.length; i < 6; i++) {
            currentlyPlaying.push({})
        }
    }

    if (display === 'CP') {
        document.title='Loople'
        return (
            <div className="home-page">
                <div className='title'>
                    <h1>Currently Playing</h1>
                </div>
                <div className='currently-playing'>
                    {currentlyPlaying && currentlyPlaying.slice(0,6).reverse().map( (game, index) => {
                        return <CurrentlyPlaying game={game} key={index} loggedIn={props.loggedIn} 
                            index={index} dialogOpen={dialogOpenIndex==index}
                            refreshCurrentlyPlaying={refreshCurrentlyPlaying}
                            refreshHomePageData={refreshHomePageData}
                            setDialogOpenIndex={setDialogOpenIndex}
                        />})
                    }
                </div>
                <div onClick={() => setDisplay('GQ')} className='queue-toggle-block action-block'>
                    <h2 className='queue-toggle'>View Game Queue</h2>
                </div>
                <div className='memory-card-block action-block'>
                    <Link className='memory-card-link' to={'memory-card'}>
                        <h2 className='memory-card-link'>Open Memory Card</h2>
                    </Link>
                </div>
            </div>
        );
    }
    else if(display === 'GQ') {
        document.title='Loople - Game Queue'
        return (
            // this could probably be done better
            // what did this note mean :joy:
            <div className="home-page">
                <GameQueue queue={gameQueue} setDisplay={setDisplay} setGameQueue={setGameQueue} 
                    refreshGameQueue={refreshGameQueue} refreshCurrentlyPlaying={refreshCurrentlyPlaying}
                    loggedIn={props.loggedIn}
                />
            </div>
        )
    }
    else if (display === 'STATS'){
        document.title='Loople - Stats'
        return (
            <div className="home-page">
                <Stats setDisplay={setDisplay}/>
            </div>
        )
    }
    else {
        throw Error("unknown display")
    }
};