

export default function GameDisplayField(props) {
    let display = props.display
    let editMode = props.editMode

    let setValue = props.setValue
    
    async function updateValue(element) {
        if (props.dataType === 'checkbox') {
            setValue(element.target.checked)
        }
        else {
            setValue(element.target.value)
        }
    }

    if (props.display == null){
        display = 'Unknown'
    }
    else if (props.display === true) {
        display = 'Yes'
    }
    else if (props.display === false) {
        display = 'No'
    }

    const inputComponent = () => {
        if (props.dataType === 'select') {
            const options = Object.entries(props.options).map(([value, text]) => 
            {   
                return <option value={value} key={value}>{text}</option>
            })

            return <>
                <select id={props.name} name={props.name} value={props.value} onChange={(el) => updateValue(el)}
                        required={!!props.required}
                >
                    {options}
                </select>
            </>
        }
        if (props.dataType === 'checkbox') {
            return <>
                <input id={props.name} name={props.name} type={props.dataType} checked={props.value} onChange={el => updateValue(el)} hidden/>
                <label className='checkmark clickable' htmlFor={props.name}/>
            </>

        }
        else {
            return <input 
                name={props.name} type={props.dataType} value={props.value} onChange={el => updateValue(el)}
                required={props.required}
            />
        }
    } 

    return (
        <span className="game-field label-parent">
            <span className='label'>{props.label}{!!props.required && editMode &&'*'}</span>
            {editMode ? 
            <span className="input">
                {inputComponent()}
            </span>
            :
            <span className="data">{display}</span>
            }
        </span>
    )
}