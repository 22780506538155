import { formatDate } from "../../helpers/date_helper"


export default function GameCard(props) {
    var game = props.game
    var listType = props.listType
    var onClick = props.onClick

    return (
        <div className="game-card-shadow" onClick={() => onClick(game.id)}>
            <div className={"game-card clickable" + (props.active ? " active":"")}>
                {game.name}
                {listType === 'completed' && <span className="Date"> {formatDate(game.date_completed, 'onlyMonthYear')}</span>}
                {listType === 'continuous' && <p>Hours Played: {game.total_hours_played}</p>}
            </div>
        </div>
    )
}