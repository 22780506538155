import { useState } from "react"
import { patchGameApiCall,queueAddAPiCall } from "../../api/MemoryCard"
import InputTextField from "../../Components/InputTextField"
import InputDateField from "../../Components/InputDateField"
import InputCheckbox from "../../Components/InputCheckbox"
import { Link } from "react-router-dom"
import InputRadioButtons from "../../Components/InputRadioButtons"
import { formatDate } from "../../helpers/date_helper"


export default function CurrentlyPlaying(props){

    const [nextStatus, setNextStatus] = useState(null)
    const [playtime, setPlaytime] = useState(props.game.total_hours_played)
    const [dateCompleted, setDateCompleted] = useState(props.game.date_completed || '')
    const [currentlyPlaying, setCurrentlyPlaying] = useState(false)


    async function onBoxClick() {
        if (props.loggedIn) {
            if (props.dialogOpen){
                props.setDialogOpenIndex()
            }
            else {
                props.setDialogOpenIndex(props.index)
            }
        }
    }

    async function submitStatusChange() {
        if (nextStatus == null && props.game.status != 'C') {
            console.error('No Status was selected')
            return
        }

        const data = {
            total_hours_played: playtime,
            date_completed: dateCompleted || null,
            currently_playing: currentlyPlaying
        }

        if (nextStatus != null) {
            data['status'] = nextStatus
        }

        if (nextStatus == 'C') {
            data['hours_when_completed'] = playtime
        }

        await patchGameApiCall(props.game.id, data)
        props.refreshCurrentlyPlaying()
        props.setDialogOpenIndex()
    }

    async function sendBackToQueue() {
        props.setDialogOpenIndex()
        await queueAddAPiCall(props.game.id)
        props.refreshHomePageData()
    }

    const possibleStates = {
        "C":"Completed",
        "H":"Hold",
        "D":"Dropped"
    }


    if (props.game.id) {
        return (
            <div className={`game${props.dialogOpen? ' open': ''}`} title={formatDate(props.game.date_started, 'short')}>
                {props.dialogOpen && <div className='dialog'>
                    <div className='dialog-items'>
                        { props.game.status != 'C' &&
                            <>
                                {/* <InputSelectField name="status" options={possibleStates} labelText="New Status" state={nextStatus} setState={setNextStatus}/> */}
                                <InputRadioButtons name="status" options={possibleStates} state={nextStatus} setState={setNextStatus} required/>
                            </>
                        }
                        <InputTextField name='total_hours_played' labelText="Playtime" state={playtime} setState={setPlaytime}/>
                        { props.game.status != 'C' && 
                            <InputDateField name="date_completed" labelText="Date Completed" state={dateCompleted} setState={setDateCompleted}/>
                        }
                        <InputCheckbox name='currently_playing' labelText="Keep Playing?" state={currentlyPlaying} setState={setCurrentlyPlaying}/>
                        <div className="dialog-buttons">
                            <button className="primary-button corner-cut" onClick={() => submitStatusChange()}>Update Game</button>
                            <button className="secondary-button corner-cut" onClick={() => sendBackToQueue()}>Back to Queue</button>
                            <Link to={`/memory-card/${props.game.id}`}><button className="link-button corner-cut">Open Game</button></Link>
                        </div>
                    </div>
                </div>}
                <div className='game-block'>
                    {props.loggedIn? 
                        <h1 onClick={() => onBoxClick()}>{props.game.name}</h1>
                        :
                        <Link to={`/memory-card/${props.game.id}`}><h1>{props.game.name}</h1></Link>
                    }
                </div>           
            </div>
        )
    }
    else {
        return (
            <div className='game'>
                <div className='game-block blank-slot'>
                    {/* TODO - replace later with properly defined height for a div instead of blank char */}
                    <h1>⠀</h1>
                </div>
            </div>
        )

    }
    
}