import { Fragment } from "react"

export default function InputRadioButtons(props) {
    async function controlledUpdate(element) {
        if (!!props.setState) {
            props.setState(element.target.value)
        }
    }

    const buttons = Object.entries(props.options).map(([value, text]) => {
        return <Fragment key={value}>
            <input 
                name={props.name}  id={value}  value={value} 
                type="radio" onChange={(el) => controlledUpdate(el)}
                hidden
            />
            <label htmlFor={value} className="radio-label">{text}</label>
        </Fragment>
    })

    return (
        <div className="input-wrapper radio-buttons">
            {!!props.parentLabel &&
                <label className="parent-label">
                    {props.parentLabel}
                </label>
            }
            {buttons}
        </div>
    )
}