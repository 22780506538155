import '../../CSS/GamePage.css'
import { useRevalidator, useLoaderData } from "react-router-dom"
import GameDisplayField from '../../Components/GameDisplayField';
import { useState } from 'react';
import {formatDate} from '../../helpers/date_helper.js'
import { GAME_STATUS } from '../../data/gameStatus.js';
import { useNavigate } from 'react-router-dom';
import CreateUpdateCheckpointModal from './CreateUpdateCheckpointModal.jsx';
import Checkpoint from './Checkpoint.jsx';
import { patchGameApiCall } from '../../api/MemoryCard.js';


export default function Game(props) {
    
    const [editMode, setEditMode ] = useState(false)
    
    const game_status_options = GAME_STATUS
    const game = useLoaderData();
    document.title='Loople - ' + game.name

    const [gameName, setGameName] = useState(game.name)
    const [continuous, setContinuous] = useState(game.continuous)
    const [lastPlayed, setLastPlayed] = useState(game.last_played?.slice(0,-4))
    const [gameStatus, setGameStatus] = useState(game.status)
    const [rating, setRating] = useState(game.rating)
    const [currentlyPlaying, setCurrentlyPlaying] = useState(game.currently_playing)
    const [dateStarted, setDateStarted] = useState(game.date_started)
    const [dateCompleted, setDateCompleted] = useState(game.date_completed)
    const [datePurchased, setDatePurchased] = useState(game.date_purchased)
    const [priceWhenPurchased, setPriceWhenPurchased] = useState(game.price_when_purchased)
    const [totalHoursPlayed, setTotalHoursPlayed] = useState(game.total_hours_played)
    const [hoursWhenCompleted, setHoursWhenCompleted] = useState(game.hours_when_completed)
        
    const [showModal, setShowModal] = useState(false)
    const [checkpointData, setCheckpointData] = useState()
    const [errorMessage, setErrorMessage ] = useState()

    const navigate = useNavigate();
    const revalidator = useRevalidator();

    const loggedIn =  props.loggedIn;

    async function openModal(checkpointToEdit) {
        if (checkpointToEdit) {
            setCheckpointData(checkpointToEdit)
        }
        else {
            setCheckpointData(null)
        }
        setShowModal(true)
    }

    async function refreshCheckpoints(){
    }

    async function updateGame() {
        var data = {
            name: gameName,
            continuous: continuous,
            last_played: lastPlayed,
            status: gameStatus,
            rating: rating,
            currently_playing: currentlyPlaying,
            date_started:dateStarted,
            date_completed:dateCompleted,
            date_purchased:datePurchased,
            price_when_purchased:priceWhenPurchased,
            total_hours_played:totalHoursPlayed,
            hours_when_completed:hoursWhenCompleted,
        }
        const response = await patchGameApiCall(game.id, data)
        if (response.ok) {
            setEditMode(false)
            setErrorMessage()
            revalidator.revalidate()
        }
        else {
            setErrorMessage('There was an error updating the game')

        }

    }

    return (
        <div className="game-page">
            <div className="header">
                <h1 className='title back-button clickable' onClick={() => navigate(-1)}>
                    <i className='back-arrow icon-size-42 white-filter'/>
                </h1>
                <h1 className='title'>{game.name}</h1>
            </div>
            <div className='info-block-shadow'>
                <div className="info-block four-corner-cut">
                    {errorMessage && <div className='error-block'>
                        {errorMessage}
                    </div>}
                    <div className='game-info'>
                        <GameDisplayField label="Name:" display={game.name} 
                            editMode={editMode}  value={gameName} setValue={setGameName}
                            dataType="text" required={true}
                        />
                        <GameDisplayField label="Continuous:" display={game.continuous} 
                            editMode={editMode} value={continuous} setValue={setContinuous}
                            dataType="checkbox" name={"continuous"}
                        />
                        <GameDisplayField label="Last Played:" display={formatDate(game.last_played, 'shortWithTime')} 
                            editMode={editMode} value={lastPlayed} setValue={setLastPlayed}
                            dataType="datetime-local"
                        />
                        <GameDisplayField label="Status" display={GAME_STATUS[game.status]} 
                            editMode={editMode} value={gameStatus} setValue={setGameStatus}
                            dataType="select" options={game_status_options} required={true}
                        />
                        <GameDisplayField label="Rating:" display={game.rating} 
                            editMode={editMode} value={rating} setValue={setRating} 
                            dataType="number"
                        />
                        <GameDisplayField label="Playing:" display={game.currently_playing} 
                            editMode={editMode} value={currentlyPlaying} setValue={setCurrentlyPlaying} 
                            dataType="checkbox" name={"playing"}
                        />
                        <GameDisplayField label="Date Started:" display={formatDate(game.date_started, 'full')} 
                            editMode={editMode} value={dateStarted} setValue={setDateStarted} 
                            dataType="date"
                        />
                        <GameDisplayField label="Date Completed:" display={formatDate(game.date_completed, 'full')} 
                            editMode={editMode} value={dateCompleted} setValue={setDateCompleted} 
                            dataType="date"
                        />
                        <GameDisplayField label="Date Purchased:" display={formatDate(game.date_purchased, 'full')} 
                            editMode={editMode} value={datePurchased} setValue={setDatePurchased} 
                            dataType="date"
                        />
                        <GameDisplayField label="Purchased For:" display={"£" + game.price_when_purchased} 
                            editMode={editMode} value={priceWhenPurchased} setValue={setPriceWhenPurchased} 
                            dataType="number" required={true}
                        />
                        <GameDisplayField label="Playtime:" display={game.total_hours_played  + " Hours"} 
                            editMode={editMode} value={totalHoursPlayed} setValue={setTotalHoursPlayed} 
                            dataType="number" required={true}
                        />
                        <GameDisplayField label="Completed Time:" display={game.hours_when_completed + " Hours"} 
                            editMode={editMode} value={hoursWhenCompleted} setValue={setHoursWhenCompleted} 
                            dataType="number" required={true}
                        />
                    </div>
                   {loggedIn && <>
                        <hr/>
                        <div className='info-buttons'>
                            {!editMode?
                                <>
                                    <button className='corner-cut' onClick={() => setEditMode(true)}>
                                        Edit
                                    </button>
                                </>
                            :
                                <>
                                    <button className='negative-button corner-cut' onClick={() => setEditMode(false)}>
                                        Cancel
                                    </button>
                                    <button className='corner-cut' onClick={() => updateGame()}>
                                        Save
                                    </button>
                                </>
                            }
                        </div>
                    </>}
                </div>
            </div>
            <CreateUpdateCheckpointModal visible={showModal}  setVisible={setShowModal} checkpointData={checkpointData} 
                refreshCheckpoints={refreshCheckpoints} game_id={game.id}
            />
            <div className="checkpoint-block">
                
                <div className="checkpoint-header">
                    {(game.checkpoints.length > 0 || props.loggedIn) && <h3>Checkpoints</h3> }
                    {loggedIn &&
                        <h3 className='new-checkpoint clickable' onClick={() => openModal()}>
                            <i className='plus icon-size-24 white-filter'/>
                        </h3>
                    }
                </div>
                <div className='checkpoint-list'>
                    {game.checkpoints.toReversed().map( checkpoint => {
                        return <Checkpoint 
                            checkpoint={checkpoint} key={checkpoint.id} openModal={openModal}
                            refreshCheckpoints={refreshCheckpoints} loggedIn={props.loggedIn}
                        />
                    })}
                </div>
            </div>
        </div>
    )
}
