import React from 'react';
import { Form, useActionData } from "react-router-dom";
import '../../CSS/Login.css'



export default function Login() {

    let login = useActionData();

    const errorBlock = () => {
        let errorMessage;
        const error_response = login.error.response_json
        if (error_response.non_field_errors?.[0] === 'Unable to log in with provided credentials.') {
            errorMessage = 'Invalid Login Credentials'
        }
        else {
            errorMessage = 'There was an error trying to log you in'
        }

        return (
            <div className='error-block'>
                <h2>{errorMessage}</h2>
            </div>
        )
    }

    return (
        <div className="login">
            {login?.error && errorBlock()}
            <div>
                <Form method="post">
                    <label>Username: <input name="username"/></label>
                    <label>Password:  <input name="password" type="password"/></label>
                    <button type="submit">Login</button>
                </Form>
            </div>
        </div>
    )

}